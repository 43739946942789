// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-icons/font/bootstrap-icons.css';

@import '~jquery-confirm/dist/jquery-confirm.min.css';

@import '../css/app.css';
