:root {
    --orange-unitec: #f68b1f;
    --orange-unitec-hover: #f7983a;
    --blue-unitec: blue;
    --red-uvm: #d7282f;
    --gray-second:rgb(136, 135, 135);
}

#tblWebinar.table-hover > tbody > tr:hover {
    background-color: var(--orange-unitec-hover) !important;
    color: white!important;
    cursor: pointer;
}

.table-hover > tbody > tr:hover {
    background-color: var(--orange-unitec-hover) !important;
    color: white!important;
    
}

.table-hover-uvm > tbody > tr:hover {
    background-color: var(--red-uvm) !important;
    color: white!important;
    
}

.descargar{
    color:inherit; 
}

.descargar:hover{
    color: white; 
}

#btnAddWebinar,.btnSave {
    float: right;
    right: 0;
}
.main-nav {
    color: white;
    background-color: var(--blue-unitec);
    border: solid 1px transparent;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 16.5px 8px 16px;
    line-height: 1.9rem;
}

.main-nav-uvm {
    color: white;
    background-color: var(--red-uvm);
    border: solid 1px transparent;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 16.5px 8px 16px;
    line-height: 1.9rem;
}

.logout-menu {
    font-weight: 500;
    border: 1px solid;
    border-color: var(--orange-unitec) !important;
    transition: 0.2s ease-out;
    padding: 0;
    text-transform: uppercase;
}

.name {
    color: white !important;
}

.logout-menu:hover {
    background-color: var(--orange-unitec);
}

.btn-menu {
    font-size: 1.125rem;
    font-weight: 500;
    width: 100%;
    margin: 0;
    border-radius: 2px;
    border: 0;
    border-color: var(--orange-unitec) !important;
    transition: 0.2s ease-out;
    color: #fff !important;
    padding: 0.85rem 2.13rem;
    white-space: normal !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
}

.btn-menu-uvm {
    font-size: 1.125rem;
    font-weight: 500;
    width: 100%;
    margin: 0;
    border-radius: 2px;
    border: 0;
    border-color: white !important;
    transition: 0.2s ease-out;
    color: #fff !important;
    padding: 0.85rem 2.13rem;
    white-space: normal !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
}

.btn-menu:hover {
    background-color: var(--orange-unitec);
    color: var(--blue-unitec);
}

.btn-menu-uvm:hover {
    background-color: white;
    color: var(--red-uvm)!important;
}

.btn-action {
    background: var(--orange-unitec) !important;
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    border-radius: 2px;
    border: 0;
    transition: 0.2s ease-out;
    color: #fff !important;
    padding: 0.85rem 2.13rem;
    white-space: normal !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
}

.btn-action-uvm {
    background: var(--red-uvm) !important;
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    border-radius: 2px;
    border: 0;
    transition: 0.2s ease-out;
    color: #fff !important;
    padding: 0.85rem 2.13rem;
    white-space: normal !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
}

.btn-action:hover {
    background-color: var(--orange-unitec-hover) !important;
    border-color: var(--orange-unitec) !important;
}

.btn-action-uvm:hover {
    background-color: var(--red-uvm) !important;
    border-color: white !important;
}


.btn-second-action {
    background: var(--gray-second) !important;
    font-size: 1.125rem;
    font-weight: 500;
    margin: 0;
    border-radius: 2px;
    border: 0;
    transition: 0.2s ease-out;
    color: #fff !important;
    padding: 0.85rem 2.13rem;
    white-space: normal !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
        0 2px 10px 0 rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
}

.btn-second-action:hover {
    background-color: var(--orange-unitec-hover) !important;
    border-color: var(--orange-unitec) !important;
}

.btn-second-action-uvm:hover {
    background-color: var(--red-uvm) !important;
    border-color: white !important;
}
